import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://fa8ed2f6c85c4f888dc368ac2eafe86f@o4504394414817280.ingest.sentry.io/4504983531421696",
  enabled: true,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,

  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
